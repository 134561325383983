.CampusBanner{
    background-image: url("https://images.pexels.com/photos/159514/yale-university-landscape-universities-schools-159514.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
    background-repeat: no-repeat;
    width: 100%;
    padding: 50px 0px 0px 10px;
    position: relative;
    background-size: cover;
    z-index: 1;
}
.campusbannerdiv{
  padding-bottom: 30px;
}
.CampusBanner .camBottom{
  display: flex;
  align-items: end;
}
.CampusBanner .camBottom img{
  /* margin: auto 0; */
  width: 100%;
  object-fit: contain;
}
.CampusBanner .campusbannerdiv h3{
    background-color: #ff9416;
    border-radius: 10px;
    padding: 10px;
    color: rgb(11, 22, 87);
    font-size: 40px;
    font-weight: 700;
    text-shadow:2px 2px 4px white;
}
.CampusBanner::before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color:rgba(0, 0, 0, 0.505);
    z-index: -1;
}
.campusProgram{
    width: 100%;
    margin: 50px 0;
    align-items: center;
}
.CampusBanner .campusProgram .Campusheading h4{
  font-size: 55px;
  text-shadow:2px 2px 4px orange;
}
.CampusBanner .campusProgram .Campusheading h4 .campusBannerSpan{
  color: #f97e04;
  text-shadow:2px 2px 4px white;
}
.CampusBanner .campusbannerdiv .headBottom .para{
  font-weight: bold;
}
.campusbannerdiv h3{
  width: 70%;
}

.campuslogo img{
  width: 250px;
}
.Campusheading h4{
    font-size: 30px;
}
.headBottom{
  background-color: #ff9416;
  border-radius: 10px;
  text-shadow:2px 2px 4px white;
  padding: 10px 20px;
}
.headBottom .para{
  width: 70%;
  margin: 0 auto;
}
.headBottom .para p{
  margin: 0;
  font-size: 20px;
}

/* CAMPUS ABOUT */
.CampusAboutSec{
  position: relative;
  padding: 50px 0;
  overflow: hidden;
}
.CampusAboutSec::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./wave.svg");
  background-repeat: no-repeat;
  background-size: cover;
  transform: rotate(0deg);
  z-index: -1;
  opacity: 0.7;
}

/* CampusAmbassadorDuration.css */
.campus-ambassador-duration {
  padding: 10% 0% 5% 0%;
  background-color: #f7f7f7;
}
.campus-ambassador-duration .DurContent .section-title {
  color: #036;
  font-size: 35px;
}
.campus-ambassador-duration .DurContent .section-description{
  color: #f97e04;
  font-weight: 600;
  font-size: 20px;
}
.campus-ambassador-duration .DurContent .section-description .sectionImp{
  color: #036;
}


.month-boxes {
  display: flex;
  justify-content: end;
}

.month-box {
  width: 100px;
  height: 100px;
  background-color: #123f6f;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-weight: 600;
  font-size: 1.5rem;
  transform-origin: center bottom;
}

.april {
  transform: translate(65px,0px) rotate(2deg);
}

.may {
  transform: translateY(-100px) rotate(30deg);
}

.june {
  transform: translate(-10px,0px) rotate(2deg);
}

.section-description {
  color: #666;
  font-size: 1.2rem;
}



/* RewardSection.css */
.RewardSec{
  padding: 50px 0 10px 0;
  background-color:#036 ;
}
.RewardSec h1{
  color: white;
  font-weight: 700;
}
.RewardSec h1 span{
  color: #fc8804;
}
.reward-letter {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.reward-letter.visible {
  opacity: 1;
}
.RewardSec .imgCol{
  position: relative;
}
.reward-image img{
 width: 100%;
}
.reward-box {
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  color: #fff;
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.reward-box h5 {
  font-size: 1.2rem;
  color: #f97e04;
  text-shadow: 1px 1px 1px black;
}

.reward-box p {
  font-size: 1rem;
}

.reward-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.reward-box {
  background-color: #2e547c;
}



/* TrainedStudents */
.TrainedStudentsSec{
  padding: 50px 0;
}
.TrainedStudentsSec .AboutText h3{
  color: #003366;
  font-weight: bold;
  font-size: 40px;
}
.TrainedStudentsSec .icon1{
  font-size: 80px;
  color: rgb(235, 163, 28);
}
.TrainedStudentsSec .icon2{
  font-size: 80px;
  color: rgb(235, 163, 28);
}
.TrainedStudentsSec .ColText{
  font-size: 30px;
  font-weight: bold;
}
.TrainedStudentsSec .AboutDiv h4{
  width: 20%;
  text-align: center;
}
.TrainedStudentsSec .AboutDiv .TrainedStudItem{
  border: 4px solid rgb(255, 165, 0,0.6);
  padding: 15px 15px;
  width: 100%;
}

/* Apply */
.ApplySec{
  background-color: #036;
  padding: 50px 0;
}
.ApplySec .ApplyDiv h4 {
  font-weight: bold;
  color: rgb(229, 135, 35);
  font-size: 40px;
  text-shadow: 2px 2px 4px black;
  padding-bottom: 10px;
}

/* WhyApply */
.WhyApplyDiv{
  padding: 18px 15px 10px 15px;
  box-shadow: 0px 0px 5px white;
  border-radius: 10px;
  width: 100%;
}
.WhyApplyDiv h5{
  font-size: 35px;
  color: orange;
}
.WhyApplyDiv p{
  padding-top: 6px;
  font-size: 16px;
  font-weight: 600;
}
.ApplyComponent{
  width: 60%;
  /* box-shadow: 5px 5px 3px white; */
  padding-right: 70px;
  position: relative;
}
.ApplyComponent::before{
  content: "";
  position: absolute;
}
.ApplyCol{
  width: 100%;
  height: auto;
  display: flex;
}
.ApplyCol .imgdiv{
  width: 30%;
}
.ApplyCol .imgdiv img{
  width: 100%;
}
.ApplyCol .WhyApplyDiv{
  width: 70%;
}


/* .WhyApplyDiv::before {
  content: "";
  background-image: url('./img.png');
  position: absolute;
  top: 50%;
  left: 0;
  width: 150px;
  height: auto;
  opacity: 1;
} */



/* What you will do */
.WhatToDo{
  border: 2px solid rgb(0, 51, 102);
  box-shadow:inset 0 0 180px  rgba(0, 51, 102, 0.5);
  padding: 50px 0;
}
.WhatToDo .WhatHead h4 {
  font-weight: bold;
  color: rgb(229, 135, 35);
  font-size: 40px;
  text-shadow: 2px 2px 4px rgb(21, 21, 102);
  padding-bottom: 20px;
}

/* Card */
/* .CardCss {
  border-bottom: 16px solid black;
  border-left: 9px solid black;
  border-top: 12px solid black;
  border-right: 6px solid black; 
}
.CardCss1 {
  border-bottom: 12px solid black;
  border-left: 6px solid black;
  border-top: 16px solid black;
  border-right: 9px solid black; 
}
.CardCss2 {
  border-bottom: 12px solid black;
  border-left: 9px solid black;
  border-top: 16px solid black;
  border-right: 6px solid black; 
}
.CardCss3 {
  border-bottom: 16px solid black;
  border-left: 6px solid black;
  border-top: 12px solid black;
  border-right: 9px solid black; 
} */
/* .CampusCard{
  width: 100%;
} */
.CampusCard{
  position: relative;
  border: 1px solid #003366;
  border-radius: 5px;
  overflow: hidden;
}
.CampusCard .CamCardImg{
  height: 250px;
}
.CampusCard .CamCardImg img{
  width:100%;
  height: 100%;
  object-fit: cover;
}
.CampusCard .CardCamDiv {
  width: 100%;
  padding: 20px;
  height: 230px;
}

/* Banner */
.BannerSection{
  background-image: url('bannerImg.jpg');
  width: 100%;
  height: auto;
  background-size: cover;
  position: relative;
  padding: 50px 0;
  z-index: 1;
}
.BannerSection::before{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color:rgba(113, 142, 193, 0.958);
  z-index: -1;
  opacity: 0.8;
}
.BannerSection .BannerHeading h3{
  font-size: 40px;
  font-weight: bolder;
}
.BannerSection .BannerHeading span{
  color: rgb(243, 114, 8);
}
.BannerSection .BannerHeading h5{
 padding: 20px 0 0 0;
}


@media (max-width: 1400px){
  .CampusCard .CardCamDiv{
    height: 270px;
  }
}
@media (max-width: 1200px){
  .CampusCard .CardCamDiv{
    height: 180px;
  }
}
@media (max-width: 992px){
  .ApplyCol .imgdiv{
    display: none;
  }
  .CampusCard .CardCamDiv{
    height: auto;
  }
  .ApplyCol .WhyApplyDiv{
    width: 100%;
  } 
  .ApplyComponent{
    width: 100%;
  }
  .ApplyComponent{
    padding: 0;
  }
  .campus-ambassador-duration .col-lg-3.d-flex.justify-content-center.align-items-center {
    margin-top: 110px;
}
}
@media (max-width: 768px){
  .campuslogo {
    display: none;
  }
}
@media (max-width: 576px) {
  .CampusBanner .campusbannerdiv h3{
    font-size: 32px;
  }
  .CampusBanner .campusbannerdiv .headBottom{
    width: 100%;
    top: 70%;
  }
  .CampusBanner .campusProgram .Campusheading h4{
    font-size: 40px;
  }
  .campuslogo img{
    padding-top: 8%;
    width:190px;
    height:160px;
    left: 5%;
  }
  .CampusBanner .camBottom img{
    display: none;
  }
  .BannerSection .BannerHeading {
    text-align: center;
  }
  .BannerSection .BannerHeading h3{
    font-size: 30px;
    font-weight: bolder;
  }
  .BannerSection .BannerHeading .ButtonBtn{
    padding: 10px 0;
  }
  .reward-image img{
   display: none;
  }
  .WhyApplyDiv h5{
    font-size: 20px;
  }
  .WhyApplyDiv p{
    font-size: 10px;
  }
  .TrainedStudentsSec{
    padding:50px 0px 10px 0px;
  }
  .TrainedStudentsSec .AboutDiv{
    padding-top: 50px;
    
  }
  .TrainedStudentsSec .AboutText h3{
    text-align: center;
  }
  .TrainedStudentsSec .icon1{
    font-size: 45px;
  }
  .TrainedStudentsSec .icon2{
    font-size: 45px;
  }
  .TrainedStudentsSec .ColText{
    font-size: 26px;
  }
  .TrainedStudentsSec .AboutDiv h4{
    width: 50%;
    text-align: justify;
  }
}