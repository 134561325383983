/* Linux Banner Css */
section.LinuxPageBanner {
    background-color: #e9f0f2;
    padding: 60px 0 0;
    position: relative;
}
section.LinuxPageBanner::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../images/LinuxWaveDesign.png");
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.5;
}
.LinuxPageBanner .LinuxBannerCon {
    position: relative;
}
.LinuxPageBanner .LinuxBannerCon h3 {
    font-size: 60px;
    font-weight: 700;
}
.LinuxPageBanner .LinuxBannerCon p {
    font-size: 20px;
}
.LinuxPageBanner .LinuxBannerCon a {
    text-decoration: none;
}
.LinuxPageBanner .LinuxBanner img {
    width: 100%;
    height: auto;
    position: relative;
}
.LinuxPageBanner .LinuxBanner{
    position: relative;
}
.LinuxPageBanner .LinuxBanner::before{
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    background-image: url("../../images/LinuxCircle.png");
    width: 120%;
    height: 100%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
}
.LinuxWhyChooseSec{
    background-color: #003366;
    padding: 50px 0;
}
.LinuxWhyChooseSec .LinuxWhyChooseCon {
    color: #fff;
}

.LinuxWhyChooseSec .LinuxWhyChooseCon h5 {
    font-size: 13px;
}

.LinuxWhyChooseSec .LinuxWhyChooseCon h3 {
    font-size: 25px;
}

.LinuxWhyChooseSec .LinuxWhyChooseCon p {
    font-size: 14px;
}

.LinuxWhyChooseSec .LinuxWhyChooseCon ul {
    display: flex;
    margin: 0;
    justify-content: space-between;
    padding: 0;
    list-style: none;
    row-gap: 20px;
    flex-wrap: wrap;
}
.LinuxWhyChooseSec .LinuxWhyChooseCon ul li {
    width: 47%;
    background-color: #ff9438;
    padding: 15px;
    border-radius: 10px;
}
.LinuxWhyChooseSec .LinuxWhyChooseCon ul li p {
    margin: 0;
}
.LinuxWhatWeOffer .LinuxWhyChooseCon ul li {
    width: 47%;
    padding: 0;
}
.LinuxWhatWeOffer .LinuxWhyChooseCon ul li button,.LinuxWhatWeOffer .LinuxWhyChooseCon ul li a {
    width: 100%;
    display: block;
    background-color: #ff9438;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #ff9438;
    color: #fff;
    text-align: center;
    text-decoration: none;
    font-weight: 700;
}
.LinuxWhyChooseSec .LinuxWhyChooseCon ul li h4{
    font-size: 19px;
    margin: 0;
    margin-bottom: 5px;
}
.LinuxWhyChooseSec .VideoDiv {
    height: 350px;
}
.LinuxWhyChooseSec .VideoDiv .WhyVideiDiv{
    height: 100%;
}

/* Journey Background */
.LinuxJourneySec {
    background-image: url("https://images.pexels.com/photos/450062/pexels-photo-450062.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0;
    background-position: center;
    text-align: center;
    position: relative;
}
.LinuxJourneySec::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #003366d1;
}
.LinuxJourneySec .LinuxJourneyDiv{
    position: relative;
    color: #fff;
}

/* Linux CTA Css */
.LinuxCtaSec {
    padding: 50px 0;
}
.LinuxCtaSec .container {
    background-color: #003366;
    padding: 40px 40px;
    border-radius: 20px;
    color: #fff;
}
.LinuxCtaSec .LinuxCTACon h3 {
    font-size: 30px;
}

.LinuxCtaSec .LinuxCTACon p {
    font-size: 17px;
}
.LinuxCtaSec .ConnectBTN {
    background-color: #ff9438;
    border: 1px solid transparent;
    border-radius: .25rem;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: calc(.875rem - 1px) calc(1.5rem - 1px);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    text-decoration: none;
    float: right;
}

/* What We Offer */
.LinuxWhatWeOffer{
    background-color: transparent;
}
.LinuxWhatWeOffer .LinuxWhyChooseCon{
    color: #003366;
}
.LinuxWhatWeOffer .LinuxWhyChooseCon ul li {
    color: #fff;
}
.LinuxWhatWeOffer .IMGDiv img {
    width: 100%;
}


.UpgradeSec{
    background-color: #003366;
    padding: 50px 0 0;
}
.UpgradeSec .UpgradeCol ul {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    align-items: center;
    padding: 0;
    list-style: none;
    margin: 0;
}
.UpgradeSec .UpgradeCol ul li {
    background-color: #fff;
    width: 35%;
    padding: 15px 20px 55px;
    position: relative;
    cursor: pointer;
    border-radius: 15px;
    border: 4px solid #fff;
}
.UpgradeSec .UpgradeCol ul li h6 {
    font-size: 22px;
    font-weight: 600;
}

.UpgradeSec .UpgradeCol ul li p {
    font-size: 15px;
}
.UpgradeSec .UpgradeCol ul li:hover{
    /* background-color: #ff953879;
    color: #fff; */
    border-color: #ff9438;
}
.UpgradeSec .UpgradeCol ul li::before {
    content: "";
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    background-image: url("../../images/WhyIcn4.png");
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.5;
}
.UpgradeSec .UpgradeCol ul li:nth-child(2)::before {
    content: "";
    background-image: url("../../images/CounterImg_4.png");
}
.UpgradeSec .UpgradeCol ul li.LastLi{
    background-color: transparent;
    height: auto;
    border: none;
    width: 24%;
}
.UpgradeSec .UpgradeCol ul li:nth-child(3)::before{
    display: none;
}
.LinuxInsSec .WhiteHeading{
    text-align: center;
    color: #fff;
}
.LinuxInsSec {
    background-color: #003366;
    padding: 50px 0;
}
.LinuxInsSec .ImgDiv img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
}
.LinuxInsSec .ImgDiv img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    object-position: top;
    border-radius: 20px;
}
section.faq-section.LinuxFaq {
    padding-top: 50px;
}
.LinuxInsSec .TeacherContent {
    color: #fff;
}
.LinuxInsSec .TeacherContent h3 {
    font-size: 25px;
}
.LinuxInsSec .TeacherContent h6 {
    font-size: 16px;
    font-weight: 700;
    color: #ff9438;
    position: relative;
    margin-bottom: 18px;
}
.LinuxInsSec .TeacherContent h6::after {
    content: "";
    position: absolute;
    bottom: -9px;
    left: 0;
    width: 50px;
    height: 1px;
    background-color: #ff9438;
}
.LinuxInsSec .TeacherContent p {
    font-size: 16px;
}
.LinuxInsSec .TeacherContent ul {
    display: flex;
    padding: 0;
    list-style: none;
    column-gap: 20px;
}
.LinuxInsSec .TeacherContent ul li a{
    font-size: 25px;
    color: #fff;
}


/* Linux Testimonial */
.LinuxTestiSec{
    padding: 50px 0;
}
.LinuxTestiSec .container {
    background-image: url("https://media.gettyimages.com/id/687830554/photo/portrait-of-a-happy-latin-american-teacher-at-the-school.jpg?s=612x612&w=0&k=20&c=JACpTuv10ZV6OF-xMi4vYHzeCZV9hoImPII11CjHMOU=");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    padding: 50px 0 50px 50px;
}
.LinuxTestiSec .container::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00336679;
}
.LinuxTestiSec .LinuxTestiDiv {
    background: linear-gradient(165deg, rgb(133 86 45) 0%, rgba(0,51,102,1) 55%);
    padding: 25px;
    border-radius: 20px;
    position: relative;
    color: #fff;
}

.LinuxTestiSec .LinuxTestiDiv h3 {
    font-size: 16px;
    margin-bottom: 0;
}
.LinuxTestiSec .LinuxTestiDiv p {
    font-size: 24px;
    margin-bottom: 10px;
}
.LinuxTestiSec .LinuxTestiDiv .LinuxTestPeople p {
    font-size: 15px;
}
.LinuxTestiSec .LinuxTestiDiv .LinuxTestPeople ul {
    list-style: none;
    padding: 0;
    display: flex;
    width: 100%;
    column-gap: 20px;
    align-items: center;
    margin: 0;
}
.LinuxTestiSec .LinuxTestiDiv .LinuxTestPeople ul li img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
}
.LinuxTestiSec .LinuxTestiDiv .LinuxTestPeople ul h3 {
    font-size: 20px;
}
.LinuxTestiSec .LinuxTestiDiv .LinuxTestPeople ul li p {
    font-size: 14px;
    margin: 0;
}
.LinuxTestiSec .LinuxTestiDiv .LinuxTestiOwlCarousel .owl-dots button.owl-dot.active span {
    background-color: #ff9438;
}


/* Linux Free Course */
.LinuxCtaSec.FreeCourseSec .ConnectBTN {
    float: initial;
    display: inline-block;
}
.LinuxCtaSec.FreeCourseSec .LinuxCTACon h3 span {
    font-size: 50px;
    font-weight: 700;
    letter-spacing: 0;
    margin: 0 auto;
    text-shadow: 0 0 80px rgba(255, 255, 255, 0.5);
    /* Clip Background Image */
    background: url("../../images/TextAnimatedImg.png") repeat-y;
    -webkit-background-clip: text;
    background-clip: text;
    /* Animate Background Image */
    -webkit-text-fill-color: transparent;
    -webkit-animation: aitf 80s linear infinite;
    /* Activate hardware acceleration for smoother animations */
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
}

/* Animate Background Image */
@keyframes aitf {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}
.AnimateText{
    animation: wiggle 2s linear infinite;
  }
  @keyframes wiggle {
    0%, 7% {
      transform: rotateZ(0);
    }
    15% {
      transform: rotateZ(-15deg);
    }
    20% {
      transform: rotateZ(10deg);
    }
    25% {
      transform: rotateZ(-10deg);
    }
    30% {
      transform: rotateZ(6deg);
    }
    35% {
      transform: rotateZ(-4deg);
    }
    40%, 100% {
      transform: rotateZ(0);
    }
  }





/* Popup */
.openAssignment {
    position: fixed;
    top: 0%;
    left: 0%;
    background-color: #0000007a;
    width: 100%;
    height: 100%;
    padding: 30px 20px;
    z-index: 999;
}
.openAssignment .AssiInner{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 30px 20px;
}
.openAssignment .AssiInner .HeadClose{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.openAssignment .AssiInner .HeadClose h4{
    font-size: 20px;
    margin-bottom: 0;
}
.openAssignment .AssiInner .HeadClose i{
    font-size: 26px;
    cursor: pointer;
}
.openAssignment .AssiInner .HeadClose i:hover{
    color: #003366;
}
.openAssignment ul{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

/* Linux Course */
.LinuxCourseCol > ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    row-gap: 20px;
    margin-top: 20px;
}

.LinuxCourseCol > ul > li {
    width: 100%;
}
.LinuxCourseCol > ul > li h6{
    font-size: 19px;
}


/* Responsive */
/* @media only screen and (min-width: 1398px) and (max-width: 1401px) {
    .LinuxRoadMapSec .RoadMapMainDiv ul::before {
        top: 50.5%;
        width: 74.5%;
        left: 9.5%;
    }
} */

@media screen and (max-width: 1400px){
    .LinuxRoadMapSec .RoadMapMainDiv ul::before {
        top: 50.5%;
        width: 74.5%;
        left: 9.5%;
    }
    .LinuxRoadMapSec .RoadMapMainDiv ul li:nth-child(4)::before {
        top: -89% !important;
    }
    .LinuxWhyChooseSec .VideoDiv .WhyVideiDiv {
      width: 100% !important;
    }
    .LinuxPageBanner .LinuxBanner::before{
      width: 150% !important;
    }
    .LinuxPageBanner .LinuxBannerCon h3 {
      font-size: 45px !important;
    }
    .LinuxPageBanner .LinuxBannerCon p {
      font-size: 17px !important;
    }
}

@media screen and (max-width: 1390px) {
    .LinuxRoadMapSec .RoadMapMainDiv ul::before {
        top: 50.5%;
        width: 73.7%;
        left: 10%;
    }
    .LinuxRoadMapSec .RoadMapMainDiv ul li:nth-child(4)::before {
        top: -89%;
        left: 52%;
    }
    .LinuxPageBanner .LinuxBanner::before {
        width: 100% !important;
        background-size: contain;
      }
  }
  @media screen and (max-width: 992px){
    .UpgradeSec .UpgradeCol ul{
        row-gap: 20px;
    }
    .UpgradeSec .UpgradeCol ul li {
        width: 100%;
    }
    .openAssignment .AssiInner{
        width: 85%;
    }
    .UpgradeSec .UpgradeCol ul li.LastLi{
        text-align: center;
    }
    .LinuxPageBanner .LinuxBanner {
        position: relative;
        text-align: end;
        margin-top: -40px;
    }
    .LinuxPageBanner .LinuxBanner img {
        width: 60%;
    }
    .LinuxPageBanner .LinuxBanner::before {
        /* transform: translate(6%, -50%); */
        display: none;
    }
    .LinuxWhatWeOffer .IMGDiv img {
        width: 40%;
    }
    .LinuxCtaSec a.ConnectBTN {
        float: initial;
        margin-top: 10px;
        display: inline-block;
    }
    .LinuxCtaSec .col-lg-3.col-12 {
        text-align: center;
    }
    .LinuxTestiSec .container{
        padding: 40px;
    }
  }

  @media screen and (max-width: 767px) {
    .LinuxWhatWeOffer .IMGDiv {
        display: none;
    }
    .LinuxWhyChooseSec {
        padding: 20px 0;
    }
    .LinuxCtaSec {
        padding: 20px 0;
    }
    .LinuxJourneySec{
        padding: 40px 0;
    }
    .LinuxTestiSec .container{
        padding: 30px;
    }
  }

  @media screen and (max-width: 575px){
    .LinuxPageBanner .LinuxBannerCon h3 {
        font-size: 35px !important;
    }
    .LinuxPageBanner .LinuxBannerCon p {
        font-size: 15px !important;
    }
    section.LinuxPageBanner{
        padding: 40px 0 0;
    }
    .LinuxWhyChooseSec .LinuxWhyChooseCon h3 {
        font-size: 20px;
    }
    .LinuxWhyChooseSec .LinuxWhyChooseCon p {
        font-weight: 300;
    }
    .LinuxWhyChooseSec .LinuxWhyChooseCon ul li h4 {
        font-size: 16px;
        margin-bottom: 2px;
    }
    .LinuxWhyChooseSec .LinuxWhyChooseCon ul li{
        width: 48%;
        padding: 10px;
    }
    .LinuxCtaSec .LinuxCTACon h3 {
        font-size: 25px;
    }
    .LinuxCtaSec.FreeCourseSec .LinuxCTACon h3 span{
        font-size: 35px;
    }
    .LinuxCtaSec .container{
        padding: 20px !important;
        border-radius: 0;
    }
    .LinuxWhatWeOffer .LinuxWhyChooseCon ul li button, .LinuxWhatWeOffer .LinuxWhyChooseCon ul li a{
        font-size: 12px;
        padding: 5px;
    }
  }