.course-card-custom {
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 15px 5px;
    list-style: none;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    row-gap: 13px;
  }
  
  .course-card-icon-custom {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    /* width: 60px; */
  }
  
  .course-card-icon-custom img {
    width: 60px;
    object-fit: contain;
    height: auto;
  }
  
  .course-card-title-custom {
    font-size: 19px;
    color: #ff9416;
    font-weight: 700;
  }
  
  .course-card-content-custom ol {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    list-style: disc;
    padding-left: 25px;
  }
  
  .course-card-content-custom ol li {
    font-size: 15px;
    padding-bottom: 8px;
  }
  
  .course-card-button-custom {
    background: linear-gradient(to right, #033567, #ff9416);
    border-radius: 12px;
    box-shadow: #ffffff 0 0 0 3px, transparent 0 0 0 0;
    color: #fff;
    border: 2px solid #ff9416;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    padding: 7px 17px;
    transition: box-shadow 0.2s;
    user-select: none;
    text-decoration: none;
    width: fit-content;
    margin: 0 auto;
    margin-top: auto;
  }
  