@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
/* Navbar */
body{
    font-family: 'Noto Sans', sans-serif;
}
header#main_navbar {
    background-color: #003366 !important;
  }
  .MainHaeder .collapse ul li.nav-item > a,.MainHaeder .collapse ul li.nav-item button {
    color: #fff;
    position: relative;
  }
  .MainHaeder .collapse ul li.nav-item a.nav-link:hover:after{
    width: 100%;
  }
  .MainHaeder .collapse ul li.nav-item a .active{
    color: #ff9416 !important;
  }
  .MainHaeder .collapse ul li.nav-item > a {
    padding: 5px 2px;
  }
  .NavbarDropdown .nav-link i{
    padding-left: 6px;
    padding-right: 6px;
  }
  
  .MainHaeder .collapse ul li.nav-item {
    padding: 0 15px;
  }
  .NavbarDropdown .NavAncDiv {
    display: flex;
    align-items: center;
    color: #fff;
  }
  .NavbarDropdown .NavAncDiv a{
    color: #fff;
  }
  .MainHaeder .collapse ul li.nav-item > a.active{
    color: #FF9416 ;
  }
  .MainHaeder .collapse ul li.nav-item > a:hover,.MainHaeder .collapse ul li.nav-item button:hover{
    color: #FF9416 ;
  }
  #DropdownButton i {
    transition: all 0.6s;
    font-size: 14px;
  }
  .arrowRotate i{
    transform: rotate(180deg);
  }
  .MainHaeder .navbar-brand img {
    width: 150px;
  }
  .NavbarDropdown {
    position: relative;
  }
  .NavbarDropdown .DropdownMenu {
    visibility: hidden;
    opacity: 0;
    top: 100%;
    position: absolute;
    text-align: left;
    -webkit-box-shadow: 0 0px 4px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 0px 0;
    margin-top: 20px;
    margin-left: 0px;
    background: #fff;
    -webkit-transition: 0.2s 0s;
    -o-transition: 0.2s 0s;
    transition: 0.2s 0s;
    border-radius: 4px;
    z-index: 999;
  }
  .NavbarDropdown .DropdownMenu:before {
    bottom: 100%;
    left: 20%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #fff;
    border-width: 10px;
    margin-left: -10px;
  }
  .NavbarDropdown:hover .DropdownMenu{
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
    margin-top: 0px;
    visibility: visible;
    opacity: 1;
  }
  .NavbarDropdown.arrowRotate .DropdownUL{
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
    margin-top: 0px;
    visibility: visible;
    opacity: 1;
  }
  .NavbarDropdown .DropdownMenu>li {
    list-style: none;
    padding: 0;
    margin: 0;
    min-width: 270px;
}
.NavbarDropdown .DropdownMenu>li a {
  font-size: 14px;
  text-transform: none;
  letter-spacing: normal;
  -webkit-transition: 0s all;
  -o-transition: 0s all;
  transition: 0s all;
  color: #343a40;
  padding: 13px 20px;
  display: block;
  text-decoration: none;
}
.NavbarDropdown .DropdownMenu>li a:hover {
  background: #ff92165d;
  color: #212529;
}
.NavbarDropdown .DropdownMenu>li a.active{
  color: #ff9416;
}
/* .TeacherContent.AshuSirLinux {
  text-align: end;
}

.TeacherContent.AshuSirLinux h6::after {
    right: 0;
    left: auto !important;
}
.TeacherContent.AshuSirLinux ul{
  justify-content: end;
} */
  
  
  .navbar_scrolled {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
  animation-name: fadeInUp;
  animation-duration: 1s;
  }
  @keyframes fadeInUp {
  from {
    opacity: 0;
    top: -50px;
  }
  to {
    opacity: 1;
    top: 0;
  }
  }

  .menuEffact.clickEffect::before {
    transform: rotateZ(47deg);
    width: 63%;
    margin: 16px 0;
  }
  .menuEffact.clickEffect::after {
    transform: rotateZ(141deg);
    width: 63%;
    margin: 10px 0px;
  }
  .menuEffact.clickEffect {
    border-top: 2px solid transparent;
    transform: rotateZ(87deg);
  }
  .navbar-mobile-menu {
    position: relative;
    width: 37px !important;
    height: 30px !important;
    background-color: #0000;
    display: inline-block;
    padding-bottom: 16px;
    border-top: 2px solid #fff;
    transition: all 0.6s ease;
  }
  .navbar-toggler:focus {
    box-shadow: none;
  }
  .navbar-mobile-menu::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 75%;
    height: 2px;
    background-color: transparent;
    border-top: 2px solid #fff;
    margin-top: 6px;
    transition: all 0.6s ease;
  }
  .navbar-mobile-menu::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    height: 2px;
    background-color: transparent;
    border-top: 2px solid #fff;
    margin-bottom: 11px;
    transition: all 0.6s ease;
  }

/* BTN Css */
.WhiteBTN {
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: transparent 0 0 0 3px,rgba(18, 18, 18, .1) 0 6px 20px;
    color: #ff9416;
    border: 2px solid #fff;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    padding: 7px 18px;
    transition: box-shadow .2s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    border: 0;
}
.WhiteBTN:hover {
    box-shadow: #FF9416 0 0 0 3px, transparent 0 0 0 0;
}

.YellowBTN {
    background-color: #ff9416;
    border-radius: 12px;
    box-shadow: #ffffff 0 0 0 3px, transparent 0 0 0 0;
    color: #fff;
    border: 2px solid #fff;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    padding: 7px 18px;
    transition: box-shadow .2s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    border: 0;
}
.YellowBTN:hover {
    box-shadow: #FF9416 0 0 0 3px, transparent 0 0 0 0;
}

/* Banner Css */
.MainBanner {
    /* background-image: url("../images/BannerBG.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    position: relative;
    padding: 50px 0; */
    /* height: 500px; */
}
.MainBanner .BannerImg{
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.MainBanner .DesktopIMG{
  display: block;
}
.MainBanner .MobileIMG{
  display: none;
}
/* .MainBanner::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00336699;
} */
.MainBanner .BannerCon{
    position: relative;
}
.MainBanner .BannerCon h3 {
    font-size: 25px;
    color: #fff;
    line-height: 36px;
}
.MainBanner .BannerCon h3 b {
    font-size: 35px;
    display: block;
}

.Headings{
    font-size: 30px;
}
.SubHeading{
    font-size: 16px;
}


/* WhyChooseSec Css */
.WhyChooseSec{
    padding: 50px 0;    
    background-color: #003366;
    color: #fff;
}
.WhyChooseSec .whyDiv {
    display: flex;
    align-items: center;
    column-gap: 20px;
    background-color: #ff9416;
    padding: 20px;
    border: 2px solid #fff;
    height: 100%;
}
.WhyChooseSec .whyDiv .icn {
  width: 20%;
}

.WhyChooseSec .whyDiv .Con {
  width: 80%;
  text-align: center;
}
.WhyChooseSec .whyDiv .icn img {
    width: 50px;
}
.WhyChooseSec .whyDiv .Con h6 {
    font-size: 21px;
    font-weight: 400;
    margin: 0;
    text-align: center;
}

/* Jump Section Css */
section.JumpCareerSec {
    background-color: #003366;
}
.JumpCareerSec .jumpCon h3 {
    font-size: 40px;
    color: #fff;
}
.JumpCareerSec .jumpCon p {
    font-size: 25px;
    color: #ff9416;
    font-weight: 500;
}
.JumpCareerSec .jumpImg img {
    width: 100%;
}

.JumpCareerSec .jumpCon ul {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    text-align: center;
    justify-content: space-between;
}

.JumpCareerSec .jumpCon ul li {
    width: 22%;
    background-color: #75849d;
    padding: 10px 15px;
    border-radius: 5px;
}

.JumpCareerSec .jumpCon ul li img {
    width: 40px;
}

.JumpCareerSec .jumpCon ul li h6 {
    font-size: 15px;
    color: #fff;
    margin-top: 10px;
}


section.WhyStuLoveSec {
    background-color: #003366;
    padding: 50px 0;
}
section.WhyStuLoveSec h2, section.WhyStuLoveSec p{
    color: #fff;
}
.WhyStuLoveSec .VideoDiv div {
    width: 100% !important;
    /* height: auto !important; */
}
.MainVideo {
  height: 250px !important;
}
.WhyStuLoveSec .VideoDiv {
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 20px 15px;
    height: 100%;
}
.WhyStuLoveSec .VideoDiv h4 {
  font-size: 20px;
  margin-top: 15px;
}
.WhyStuLoveSec .VideoDiv h6 {
    font-size: 15px;
    color: #FF9416;
}
.WhyStuLoveSec .VideoDiv p {
    font-size: 14px;
    margin: 0;
    color: #000;
}


/* Trusted Collage Css */
section.TrusCollSec {
    background-color: #033567;
    padding: 0 0 50px;
}
.TrusCollSec .CollageImg {
    background-color: white;
    padding: 25px;
    border-radius: 15px;
}
.TrusCollSec .CollageImg img {
    width: 100% !important;
    height: 140px;
    object-fit: contain;
}

/* Explore Courses Section Css */
section.ExpCourSec {
  background-image: url("../images/Banner-BG-NEW.webp");
  position: relative;
  height: 360px;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
section.ExpCourSec::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00336699;
}
.ExpCourSec .ExploreCouCon {
    position: relative;
    color: #fff;
}
.ExpCourSec .ExploreCouCon h4 {
    font-size: 40px;
    width: 70%;
    margin: 0 auto;
}
section.CoursesSec {
  background-color: #033567;
  padding: 50px 0;
}
.CoursesSec .CoursesDiv ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  row-gap: 16px;
  column-gap: 16px;
}
.CoursesSec .CoursesDiv ul > li {
  width: 23%;
  background-color: #fff;
  padding: 15px 5px;
  list-style: none;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 13px;
}
.CoursesSec .CoursesDiv ul li .icn {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}
.CoursesSec .CoursesDiv ul li .icn img {
  width: 70px;
  object-fit: contain;
  height: auto;
}
.CoursesSec .CoursesDiv ul li .con {
  font-size: 19px;
  color: #ff9416;
  font-weight: 700;
}
.CoursesSec .CoursesDiv ul li .curriculumBTN {
  background: linear-gradient(to right, #033567 , #ff9416);
  border-radius: 12px;
  box-shadow: #ffffff 0 0 0 3px, transparent 0 0 0 0;
  color: #fff;
  border: 2px solid #ff9416;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  padding: 7px 17px;
  transition: box-shadow .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  /* border: 0; */
  text-decoration: none;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  margin-top: auto;
}
.CounterSec .CounSec ul {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  margin: 0;
}
.CounterSec .CounSec ul li {
  list-style: none;
  width: 19%;
  text-align: center;
  border: 1px solid #ff94166b;
  padding: 10px 20px;
  border-radius: 10px;
}
.CounterSec .CounSec ul li .icn{
  margin-bottom: 20px;
}
.CounterSec .CounSec ul li .icn img {
  width: 70px;
}
.CounterSec .CounSec ul li .con h3 {
  font-size: 25px;
  font-weight: 600;
}
.CounterSec .CounSec ul li .con p {
  font-size: 23px;
}
section.CounterSec {
  padding: 50px 0;
}
.CurriculumModel a.YellowBTN {
  text-decoration: none;
}
.CoursesSec h2.Headings {
  color: #fff;
  margin-bottom: 30px;
}


/* Whatsapp ICN CSS */
.WhatSappIcn {
  position: fixed;
  bottom: 45px;
  z-index: 99;
  left: 47px;
  background-color: #25D366;
  border: #25D366;
  width: 70px;
  height: 68px;
  text-align: center;
  line-height: 68px;
  border-radius: 63%;
  size: auto;
}
.WhatSappIcn i {
  font-size: 37px;
  color: #fff;
  line-height: 68px;
}

/* Gallery Css */
.GallerySec{
  padding: 50px 0;
  background-color: #003366;
}

.container__img-holder img {
  width: 100%;
  height: 220px;
  display: block;
  object-fit: cover;
  cursor: pointer;
}

.CoursesSec .CoursesDiv ul li .conLI ol {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  list-style: disc;
  padding-left: 25px;
  
}
.CoursesSec .CoursesDiv ul li .conLI ol li{
  font-size: 15px;
  padding-bottom: 8px;
}


/* Popup Styling */
.img-popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, .5);
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  z-index: 99;
}

.img-popup img {
  max-width: 900px;
  width: 100%;
  opacity: 0;
  transform: translateY(-100px);
  -webkit-transform: translateY(-100px);
  -moz-transform: translateY(-100px);
  -ms-transform: translateY(-100px);
  -o-transform: translateY(-100px);
}

.close-btn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background-color: #ff9416;
  width: 50px;
  height: 50px;
  padding: 10px;
}

.close-btn .bar {
  height: 4px;
  background: #fff;
}

.close-btn .bar:nth-child(1) {
  transform: rotate(45deg);
}

.close-btn .bar:nth-child(2) {
  transform: translateY(-4px) rotate(-45deg);
}

.opened {
  display: flex;
}

.opened img {
  animation: animatepopup 1s ease-in-out .8s;
  -webkit-animation: animatepopup .3s ease-in-out forwards;
}

@keyframes animatepopup {

  to {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }

}
.GallerySec h2.Headings {
  margin-bottom: 30px;
}
.GallerySec .GalleryDiv {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  row-gap: 30px;
}
.GallerySec .GalleryDiv .container__img-holder {
  width: 23%;
}
.GallerySec .container__img-holder.FiftyIMG {
  width: 50%;
  height: 320px !important;
}
.GallerySec .container__img-holder.FiftyIMG img{
  height: 100%;
}
.GallerySec .container__img-holder.MarginTop {
  margin-top: -90px;
}


/* Summer Section */
section.SummerSec {
  padding: 50px 0;
}
.YellowHeading {
  text-align: center;
  color: #ff9416;
  width: 60%;
  margin: 0 auto;
  font-weight: 700;
}
.SummerSec .headingdiv p {
  text-align: center;
  padding: 10px 0;
}
.SummerSec .SummerDiv {
  background-color: #003366;
  text-align: center;
  padding: 35px 20px;
  color: #fff;
  border-radius: 20px;
  height: 100%;
}
.SummerSec .SummerDiv ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.SummerSec .SummerDiv h3 {
  font-size: 25px;
  margin-bottom: 15px;
}
.SummerSec .SummerDiv ul li {
  margin-bottom: 5px;
}
.SummerSec .SummerDiv{
  position: relative;
}
.SummerSec .SummerDiv.first::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 80px;
  height: 80px;
  background-image: url("../images/SummerIcn1.png");
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.5;
}
.SummerSec .SummerDiv.second::before {
  content: "";
  position: absolute;
  bottom: 5px;
  right: 10px;
  width: 80px;
  height: 80px;
  background-image: url("../images/SummerIcn2.png");
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.5;
}
.SummerSec .SummerDiv.third::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 10px;
  width: 60px;
  height: 70px;
  background-image: url("../images/SummerIcn3.png");
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.5;
}


/* Benefit Summer */
section.BenefitSummer {
  background-color: #003366;
  padding: 40px 0;
}
.BenefitSummer p.subHeading {
  font-size: 15px;
  text-align: center;
  color: #fff;
  margin: 0;
}
.BenefitMainDIv{
  padding: 30px 0 0;
}
.BenefitMainDIv ul.BenefitUL {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  row-gap: 20px;
  column-gap: 20px;
  padding: 0;
  list-style: none;
}

.BenefitMainDIv ul.BenefitUL li {
  background-color: #fff;
  width: 32%;
  color: #036;
  text-align: center;
  padding: 30px;
  border-radius: 20px;
  border: 5px solid #003366;
}
.BenefitMainDIv ul.BenefitUL li:hover{
  border: 5px solid #FF9416;
}
.BenefitMainDIv ul.BenefitUL li h6 {
  font-size: 20px;
  color: #ff9416;
  font-weight: 700;
}
.BenefitMainDIv ul.BenefitUL li p {
  font-size: 15px;
  font-weight: 300;
}

/* Look at Work Css */
section.TrusCollSec.LookOutWork {
  padding: 50px 0;
}
.TrusCollSec.LookOutWork .Headings {
  font-size: 27px;
}
.TrusCollSec.LookOutWork .SubHeading {
  font-size: 35px;
  color: #FF9416 !important;
  font-weight: 800;
}

/* Footer Css */
.FooterContact ul li a:hover{
  cursor: initial;
  color: #a0a0a0 !important;
}
footer.Footermain {
  background-color: #000;
  padding: 50px 0;
}

.Footermain .Footerlogo img {
    width: 100%;
}
.Footermain .footerLinks h5 {
  font-size: 25px;
  color: #fff;
  margin-bottom: 25px;
}

.Footermain .footerLinks ul {
    padding: 0;
    list-style-type: none;
}
.Footermain .footerLinks ul >li {
  padding: 0;
  list-style-type: none;
  text-align: left !important;
}
@media screen and (max-width: 767px){
  .Footermain .footerLinks ul {
    padding: 0;
    list-style-type: none;
}
.Footermain .footerLinks ul li {
  padding-bottom: 10px;
  text-align: center;
}
.Footermain h5{
  text-align: center;
}
.Footerlogo img{
  text-align: center;
}
.Footerlogo{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
}
.Footermain .footerLinks ul li {
  padding-bottom: 10px;
  text-align: center;
}
.Footermain .footerLinks ul li a {
  text-decoration: none;
  color: #a0a0a0;
  
}
.Footermain .footerLinks ul li a:hover{
  color: #FF9416;
}
.Footermain .Footerlogo img.QRLogo {
  width: 60%;
}
.row.footerCopyRight {
  margin-top: 20px;
  border-top: 1px solid #fff;
  padding-top: 20px;
}
.footerCopyRight ul.SocialMedia {
  display: flex;
  column-gap: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
}
.footerCopyRight .CopyRight h6 {
  color: #fff;
}
.footerCopyRight ul.SocialMedia li a {
  color: #ff9e2b;
  background-color: #fff;
  padding: 5px 8px;
  font-size: 18px;
  border-radius: 5px;
}

/* Testimonial CSS */
.TestimonialSection {
  background-color: #003366;
  padding: 50px 0;
}
.TestimonialSection .TestiDiv {
  background-color: #fff;
  padding: 20px;
  text-align: center;
  border-radius: 20px;
  height: 100%;
}
.TestimonialSection .TestiDiv img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}
.TestimonialSection .TestiDiv h5 {
  font-size: 20px;
  font-weight: 600;
  color: #FF9416;
}
.TestimonialSection .TestiDiv h6 {
  font-size: 16px;
}
.TestimonialSection .TestiDiv p {
  font-size: 14px;
}
.TestimonialSection .TestiDiv img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: -90px;
  margin-bottom: 20px;
}
.MarginTop110{
  margin-top: 110px;
}


/* Expert Team Section */
.ExpertTrainSec{
  padding: 50px 0;
}
.ExpertTrainSec .YellowHeading{
  margin-bottom: 30px;
}
.ExpertTrainSec .TeamDiv {
  display: flex;
  column-gap: 30px;
  padding: 30px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  height: 100%;
}
.ExpertTrainSec .TeamDiv .Fir .imgDiv {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
}
.ExpertTrainSec .TeamDiv .Fir .imgDiv img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}
.ExpertTrainSec .TeamDiv .Sec h3 {
  font-size: 25px;
  font-weight: 800;
  margin-bottom: 5px;
}
.ExpertTrainSec .TeamDiv .Sec h6 {
  font-size: 16px;
  font-weight: 700;
  color: #ff9438;
  position: relative;
  margin-bottom: 18px;
}
.ExpertTrainSec .TeamDiv .Sec h6::after {
  content: "";
  position: absolute;
  bottom: -9px;
  left: 0;
  width: 50px;
  height: 1px;
  background-color: #ff9438;
}
.ExpertTrainSec .TeamDiv .Sec h6::after {
  content: "";
  position: absolute;
  bottom: -9px;
  left: 0;
  width: 50px;
  height: 1px;
  background-color: #ff9438;
}

/* Certificate Css */
section.CertificateSection {
  /* background-color: #033567; */
  padding: 50px 0;
}
.CertificateSection .CertiDiv img {
  width: 100%;
}
.CertificateSection .YellowHeading{
  margin-bottom: 30px;
}

/* FAQ CSS */
.faq-section {
  background: #fdfdfd;
  padding-bottom: 50px;
}
.faq-title p {
padding: 0 190px;
margin-bottom: 10px;
}

.faq {
background: #FFFFFF;
box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
border-radius: 4px;
}
.faqcard .accordion-button:not(.collapsed) {
  background-color: #ff92165d;
}
.faqcard .faqHeading button {
  font-size: 17px;
  font-weight: 500;
}
.faqcard .accordion-body {
  font-size: 15px;
  color: #6f8ba4;
}


/* Form Css */
.PopUpmodal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* opacity: 0;
  visibility: hidden;
  display: none; */
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
  z-index: 999;
}
.PopUpShow{
  display: block;
}
.modalPopUpcontent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  border-radius: 0.5rem;
  background-color: #003366;
}
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header h4#myModalLabel {
  color: #fff;
  font-size: 14px;
}
button.close.close-button {
  background-color: transparent;
  border: none;
  padding: 4px 10px;
  line-height: 20px;
}
.modalPopUpcontent .modal-body {
  position: relative;
  padding: 10px 15px 20px;
}
.modalPopUpcontent .modal-body .YellowBTN {
  font-size: 15px;
  padding: 5px 13px;
}
button.close.close-button span {
  font-size: 25px;
  color: #fff;
}
.modal-header.border-0 button span {
  color: #fff;
}
.w3l-contacts-12 form.main-input input, .w3l-contacts-12 textarea {
  color: var(--font-color);
  font-size: 14px;
  line-height: 20px;
  padding: 9px 20px;
  outline: none;
  border: 1px solid #fff;
  border-radius: 5px;
  width: 100%;
  background: #fff;
  margin-bottom: 10px;
}
.serSel select {
  color: #666;
  font-size: 14px;
  line-height: 20px;
  padding: 9px 20px;
  outline: none;
  border: 1px solid #eee;
  border-radius: var(--border-radius);
  width: 100%;
  background: #f8f9fa;
  position: relative;
  -webkit-appearance: none;
  border-radius: 5px;
  margin-bottom: 10px;
}
.serSel{
  position: relative;
}
.serSel::before {
  content: "▼";
  position: absolute;
  top: 38%;
  right: 0px;
  cursor: pointer;
  pointer-events: none;
  z-index: 9;
  transform: translateY(-50%);
  color: rgb(63, 58, 100);
  padding: 0px 1em;
  transition: all 0.25s ease 0s;
  font-size: 12px;
}


/* Banner Form */
.BannerFormDiv {
  position: relative;
  width: 70%;
  margin: 0 auto;
  background-color: #003366;
  padding: 20px;
  border-radius: 10px;
}
.BannerFormDiv h3{
  color: #fff;
  border-bottom: 1px solid #c3c0c0;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.BannerFormDiv .inputDiv label {
  display: block;
  font-size: 15px;
  color: #fff;
  margin-bottom: 5px;
}
.BannerFormDiv .inputDiv {
  margin-bottom: 15px;
}
.BannerFormDiv .inputDiv input, .BannerFormDiv .inputDiv select {
  width: 100%;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #fff;
  outline: none;
  font-size: 15px;
}
.BannerFormDiv .SelectDiv{
  position: relative;
  -webkit-appearance: none;
}
.BannerFormDiv .SelectDiv select{
  -webkit-appearance: none;
}
.BannerFormDiv .SelectDiv::before {
  content: "▼";
  position: absolute;
  top: 70%;
  right: 0px;
  cursor: pointer;
  pointer-events: none;
  z-index: 9;
  transform: translateY(-50%);
  color: rgb(63, 58, 100);
  padding: 0px 1em;
  transition: all 0.25s ease 0s;
}

/* Pop Up Form */
.PopUpForm {
  background-color: #0000008f;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.PopUpForm .BannerFormDiv {
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.PopUpForm .BannerFormDiv .modal-title {
  font-size: 22px !important;
}
.PopUpForm .BannerFormDiv .modal-header {
  padding: 0px 0px 10px 0px;
}
.PopUpForm{
  display: none;
}
.PopUpShowForm{
  display: block !important;
}

/* Registration Page */
.RegisterSection{
  padding: 50px 0;
  font-weight: 600;
}
.RegisterColumn .mycol{
  display: flex;
  flex-direction: column;
}
.RegisterColumn .mycol label{
  font-size: 19px;
  margin-bottom: 5px;
}
.RegisterColumn .mycol input{
  height: 50px;
  border-radius: 5px;
  padding: 10px 20px;
  border: 2px solid #000;
}
.RegisterColumn .mycol select{
  height: 50px;
  border-radius: 5px;
  padding: 10px 20px;
  border: 2px solid #000;
  background-color: transparent;
}
.RegisterColumn .mycol .courses{
  display: flex;
  column-gap: 30px;
}
.RegisterColumn .mycol .courses .chooseBox label{
  margin: 0;
}
.RegisterColumn .mycol .courses .chooseBox{
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.RegisterColumn .mycol .courses .chooseBox input{
  width: 15px;
  height: 15px;
}
.RegisterSection .RegisterHeading{
  font-size: 30px;
  font-weight: 700;
}
.RegisterSection .step-numbers{
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: 0 auto;
}
.RegisterSection .step-numbers .stepdiv{
  position: relative;
  width: 33%;
}
.RegisterSection .step-numbers .stepdiv span::before{
  content: "";
  position: absolute;
  top: 50%;
  right: -100%;
  width: 100%;
  height: 3px;
  transform: translate(-50%, -50%);
  background-color: #003366;
  z-index: -1;
}
.RegisterSection .step-numbers .stepdiv:last-child span::before{
  display: none;
}
.RegisterSection .step-numbers span {
  font-size: 25px;
  background-color: #003366;
  width: 50px;
  height: 50px;
  display: inline-block;
  line-height: 50px;
  border-radius: 50%;
  color: #fff;
}
.RegisterSection .step-numbers .active span{
  background-color: #FF9416;
}
.RegisterSection .step-numbers .active span::before{
  background-color: #FF9416;
}
.RegisterSection .RegisterColumn .ButtonsDiv{
  display: flex;
  flex-direction: row;
  column-gap: 30px;
}
.RegisterSection .RegisterColumn .ButtonsDiv .RegisterButton{
  width: fit-content;
}



/* Responsive */
@media screen and (max-width: 1400px) {
  .MainHaeder .collapse ul li.nav-item {
    padding: 0 8px;
  }
  .WhyChooseSec .whyDiv .Con h6{
    font-size: 20px;
  }
  .WhyChooseSec .whyDiv .icn img {
    width: 40px;
  }
  .CoursesSec .CoursesDiv ul li .conLI ol{
    
  }
}

@media screen and (max-width: 1200px){
  .MainHaeder .collapse ul li.nav-item > a, .MainHaeder .collapse ul li.nav-item > button {
    font-size: 15px;
  }
  .WhiteBTN{
    font-size: 15px;
  }
  .YellowBTN{
    font-size: 15px;
  }
  .BenefitMainDIv ul.BenefitUL li{
    width: 30%;
  }
  .CoursesSec .CoursesDiv ul{
    row-gap: 25px;
  }
  .CoursesSec .CoursesDiv ul > li{
    width: 48%;
  }
  .CoursesSec .CoursesDiv ul li .conLI ol {
    height: 77px;
  }
  .MainHaeder .navbar-brand img {
    width: 125px;
  }
  .MainHaeder a.navbar-brand {
    margin-right: 5px;
  }
  .MainHaeder .collapse ul li.nav-item {
    padding: 0 4px;
  }
  .NavbarDropdown .nav-link i{
    font-size: 13px;
  }
}

@media screen and (max-width: 992px){
  .JumpCareerSec .jumpCon h3 {
    font-size: 28px;
    line-height: 38px;
  }
  .JumpCareerSec .jumpCon p{
    font-size: 18px;
  }
  .JumpCareerSec .jumpCon ul li img {
    width: 30px;
  }
  .JumpCareerSec .jumpImg img {
    height: 250px;
    object-fit: cover;
  }
  .Headings {
    font-size: 25px;
  }
  .SubHeading {
    font-size: 15px;
  }
  .TrusCollSec .CollageImg img{
    height: 100px;
  }
  .ExpCourSec .ExploreCouCon h4 {
    font-size: 28px;
    width: 100%;
  }
  .CoursesSec .CoursesDiv ul > li .icn img{
    width: 100px;
  }
  .CounterSec .CounSec ul {
    row-gap: 30px;
    justify-content: flex-start;
    column-gap: 20px;
  }
  .CounterSec .CounSec ul li{
    width: 30%;
    padding: 10px 15px;
  }
  section.ExpCourSec{
    height: 220px;
  }
  .CounterSec .CounSec ul li .icn img {
    width: 70px;
  }
  .CounterSec .CounSec ul li .con h3{
    font-size: 22px;
  }
  .CounterSec .CounSec ul li .con p{
    font-size: 20px;
  }
  .GallerySec .GalleryDiv .container__img-holder {
    width: 31%;
  }
  .GallerySec .container__img-holder.FiftyIMG{
    width: 50% !important;
  }
  .GallerySec .GalleryDiv .container__img-holder:nth-child(1){
    width: 48%;
  }
  .GallerySec .GalleryDiv .container__img-holder:nth-child(3){
    width: 48%;
    margin-top: -90px;
  }
  .GallerySec .GalleryDiv .container__img-holder:nth-child(4){
    width: 50%;
  }
  .GallerySec .GalleryDiv .container__img-holder:nth-child(5){
    width: 48%;
    margin-top: -90px;
  }
  .GallerySec .GalleryDiv .container__img-holder:nth-child(6){
    width: 50%;
  }
  .GallerySec .GalleryDiv .container__img-holder:nth-child(7){
    width: 48%;
    margin-top: -90px;
  }
  .GallerySec .container__img-holder.MarginTop{
    margin-top: 0;
  }
  .YellowHeading{
    font-size: 25px;
  }
  .SummerSec .SummerDiv h3 {
    font-size: 20px;
  }
  section.SummerSec {
    padding: 30px 0;
  }
  section.BenefitSummer {
    padding: 30px 0;
  }
  .BenefitMainDIv ul.BenefitUL{
    column-gap: 0;
    justify-content: space-between;
  }
  .BenefitMainDIv ul.BenefitUL li {
    width: 48%;
  }
  .ExpertTrainSec {
    padding: 30px 0;
  }
  .TestimonialSection {
    padding: 30px 0;
  }
  .TestimonialSection .row.MarginTop110 .col-12:nth-child(3){
    margin-top: 100px;
  }
  .TestimonialSection .row.MarginTop110 .col-12:nth-child(4){
    margin-top: 100px;
  }
  section.CertificateSection {
    padding: 30px 0;
  }
  .CoursesSec .CoursesDiv ul li .conLI ol {
    height: 95px;
  }
}

@media screen and (max-width: 767px){
  .GallerySec .GalleryDiv .container__img-holder{
    width: 49% !important;
    margin-top: 0 !important;
  }
  .GallerySec .GalleryDiv{
    row-gap: 20px;
  }
  .GallerySec .container__img-holder.FiftyIMG{
    height: 220px !important;
  }
  .GallerySec .GalleryDiv .container__img-holder:nth-child(7){
    width: 100% !important;
  }
  .YellowHeading{
    width: 100%;
  }
  .Footermain .Footerlogo img {
    width: 52%;
  }
  .Footermain .Footerlogo img.QRLogo {
    width: 49%;
  }
  .CertificateSection .YellowHeading {
    margin-bottom: 20px;
  }
  section.ExpCourSec {
    height: 310px;
  }
  .modalPopUpcontent{
    width: 70%;
  }
  .BannerFormDiv{
    width: 100%;
  }
  .ExpertTrainSec .TeamDiv{
    flex-direction: column;
  }
  .WhatSappIcn i {
    font-size: 35px;
    line-height: 60px;
  }
  .WhatSappIcn {
    width: 60px;
    height: 60px;
    left: 31px;
    bottom: 30px;
    font-weight: 600;
  }
  div#hubspot-messages-iframe-container {
    display: none !important;
  }
  .WhatsAppDIV {
    display: block;
    width: 100%;
    background-color: #ff9416;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 60px;
    z-index: 999;
    text-align: center;
  }
  
  .CoursesSec .CoursesDiv ul > li {
    width: 100%;
  }
  .CoursesSec .CoursesDiv ul li .conLI ol {
    height: auto;
  }
  .WhatsAppDIV .WhatSappIcn {
    position: relative;
    background-color: transparent;
    display: flex;
    justify-content: center;
    width: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    align-items: center;
    column-gap: 10px;
    text-decoration: none;
    font-size: 21px;
  }
  .WhatsAppDIV .WhatSappIcn::before{
    content: "Chat With Us";
    color: #fff;
  }
  footer.Footermain{
    padding-bottom: 70px;
  }

  .WhyChooseSec{
    padding: 30px 0;
  }
}

@media screen and (max-width: 550px){
  section.CoursesSec {
    padding: 30px 0;
  }
  .CounterSec .CounSec ul li {
    width: 47%;
  }
  .CounterSec .CounSec ul {
    row-gap: 20px;
  }
  .GallerySec {
    padding: 30px 0;
  }
  .BenefitMainDIv ul.BenefitUL li {
    width: 98%;
  }
  .TestimonialSection .row.MarginTop110 .col-12:nth-child(2){
    margin-top: 100px;
  }
  .MarginTop110 {
    margin-top: 100px;
  }
  .JumpCareerSec .jumpCon ul{
    row-gap: 20px;
  }
  .JumpCareerSec .jumpCon ul li {
    width: 48%;
  }
  .MainBanner .BannerCon h3 {
    font-size: 22px;
  }
  .MainBanner .BannerCon h3 b {
    font-size: 30px;
  }
  .MainBanner .YellowBTN {
    margin-top: 12px !important;
  }
  .TestimonialSection .TestiDiv img {
    width: 120px;
    height: 120px;
  }
  .ExpCourSec .ExploreCouCon h4 {
    font-size: 25px;
  }
  section.ExpCourSec {
    height: 280px;
  }
  .PopUpForm .BannerFormDiv {
    width: 85%;
  }
  .modalPopUpcontent{
    width: 77%;
  }
  .w3l-contacts-12 form.main-input input, .w3l-contacts-12 textarea{
    padding: 5px 20px;
  }
  .serSel select{
    padding: 5px 20px;
  }
  .MainBanner .DesktopIMG{
    display: none !important;
  }
  .MainBanner .MobileIMG{
    display: block !important;
  }
}

@media screen and (max-width: 450px){
  .JumpCareerSec .jumpCon h3 {
    font-size: 25px;
  }
  .JumpCareerSec .jumpCon p {
    font-size: 16px;
  }
  section.WhyStuLoveSec {
    padding: 30px 0;
  }
  .ExpertTrainSec .TeamDiv{
    padding: 21px 19px;
  }
  .ExpertTrainSec .TeamDiv .Sec h3 {
    font-size: 21px;
  }
  .ExpertTrainSec .TeamDiv .Sec h6 {
    font-size: 15px;
  }
  .ExpertTrainSec .TeamDiv .Sec p{
    font-size: 14px;
  }
  .CounterSec .CounSec ul li {
    width: 100%;
  }
}