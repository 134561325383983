.main-section {
  padding: 3rem 0;
  display: flex;
  justify-content: center;
}

.sub-main-section {
  width: 70%;
  margin: 0 auto;
}

.sub-main-section div.heading-div {
  margin-bottom: 3rem;
}

.heading-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.register-heading {
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 700;
  color: #003366;
}

.form-nav-div {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin: 0 auto;
}

.form-nav-div .form-nav-num {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  color: #ffffff;
  background: #003366;
  padding: 0.5rem 1rem;
  border-radius: 100%;
}

.form-div {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  column-gap: 3rem;
  row-gap: 1rem;
}

.form-sub-div {
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
}

.form-row {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-label {
  font-weight: 600;
  color: #003366;
}

.form-input {
  font-size: 0.875rem;
  /* font-size: 1rem; */
  line-height: 1.25rem;
  border-width: 1px;
  border-color: #003366;
  padding: 0.5rem 0.75rem;
}

.form-error {
  color: rgb(153 27 27);
}

.form-select-input {
  padding: 0.5rem 1rem;
  width: 100%;
}

.form-submit-btn {
  color: white;
  background-color: #ff9416;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  cursor: pointer;
  border: none;
  font-weight: 700;
}

.form-btn-div {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.form-fees {
  padding: 0.5rem 0;
  font: 600;
  color: #003366;
  font-size: 1rem;
  line-height: 1.75rem;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  p {
    margin: 0;
  }

  span {
    display: flex;
    flex-direction: column;
  }
}

.coupon-section {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.coupon-code {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  button {
    font-size: 0.9rem;
    margin-top: 5px;
  }
}

.payment-form-div {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.payment-form {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
}

.payment-form-heading {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
  color: #003366;
}
.payment-success-page {
  height: 80vh;
  padding: 0 7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.success-up-logo {
  width: 20rem;
}

.payment-success-msg-div {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.payment-success-msg-1 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 600;
  color: #003366;
  text-align: left;
}

.payment-success-msg-2 {
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
  color: rgb(41, 177, 207);
  display: flex;
  gap: 1rem;
  justify-content: start;
  align-items: center;

  a {
    color: rgb(41, 177, 207);
  }
}

.homepage-link {
  color: rgb(24, 24, 181);
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
  text-decoration: underline;
  text-align: left;
}

/* Complate Payment Design */
.completePayment {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 60%;
  border: 1px solid;
  margin: 0 auto;
  padding: 50px;
}
.completePayment .paymentHeader {
  display: flex;
  justify-content: space-between;
}
.completePayment .payment-form-heading {
  font-size: 17px;
  border-bottom: 1px solid #00000041;
  padding-bottom: 10px;
}
.completePayment .RegistrationAmount {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.completePayment .RegistrationAmount h6 {
  font-size: 20px;
}
.completePayment .RegistrationAmount b {
  font-size: 20px;
}

.bubble-msg {
  animation-name: bubble;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  background-color: black;
  color: white;
  padding: 0.5rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  font-weight: 600;

  p {
    margin: 0;
  }
}

@keyframes bubble {
  0% {
    /* transform: translateY(0px); */
    background-color: #003366;
    color: white;
  }
  /* 25% {
    transform: translateY(3px);
  } */
  50% {
    /* transform: translateY(5px); */
    background-color: white;
    color: #003366;
  }
  /* 75% {
    transform: translateY(3px);
  } */
  100% {
    /* transform: translateY(0px); */
    background-color: #003366;
    color: white;
  }
}

@media screen and (max-width: 640px) {
  .sub-main-section {
    width: 90%;
  }
  .completePayment {
    width: 100% !important;
    padding: 20px;
  }
  .form-div {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .completePayment .form-btn-div {
    flex-direction: column;
    align-items: self-start;
    gap: 0.8rem;
  }

  .payment-success-page {
    padding: 0 2rem;
  }

  .success-up-logo {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .payment-success-msg-1 {
    font-size: 1.25rem; /* 20px */
    line-height: 1.75rem;
    text-align: center;
  }

  .payment-success-msg-2 {
    justify-content: center;
  }

  .homepage-link {
    text-align: center;
  }
  .completePayment {
    width: 70%;
  }

  .coupon-section {
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 1224px) {
  .completePayment {
    width: 90%;
  }
}
@media screen and (min-width: 640px) and (max-width: 1280px) {
  .payment-success-page {
    padding: 0 5rem;
  }
}
